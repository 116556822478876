import { Partenaire } from "./Partenaire";
import { Plateforme } from "./Plateforme";
import { Service } from "./Service";
import { Transaction } from "./Transaction";

export class ResponseCheckUuidtransaction {
  private _transaction: Transaction;
  private _services: Service;
  private _partenaire: Partenaire;
  private _plateforme: Plateforme;

  constructor(
    transaction: Transaction,
    services: Service,
    partenaire: Partenaire,
    plateforme: Plateforme
  ) {
    this._partenaire = partenaire;
    this._services = services;
    this._transaction = transaction;
    this._plateforme = plateforme;
  }

  static deserialize(data: any): ResponseCheckUuidtransaction {
    return (
      new ResponseCheckUuidtransaction(
        Transaction.deserialize(data.data.payload.transaction),
        Service.deserialize(data.data.payload.customService),
        Partenaire.deserialize(data.data.payload.partenaire),
        Plateforme.deserialize(data.data.payload.plateforme)
      )
    );
  }

  get transaction(): Transaction {
    return this._transaction;
  }

  set transaction(value: Transaction) {
    this._transaction = value;
  }

  get services(): Service {
    return this._services;
  }

  set services(value: Service) {
    this._services = value;
  }

  get partenaire(): Partenaire {
    return this._partenaire;
  }

  set partenaire(value: Partenaire) {
    this._partenaire = value;
  }

  get plateforme(): Plateforme {
    return this._plateforme;
  }

  set plateforme(value: Plateforme) {
    this._plateforme = value;
  }
}
