import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { ResponseCheckUuidtransaction } from "../../model";
import { GlobalContextData } from "../../model/contextData";
import { MobileMoneyForm, WaveForm } from "../../model/payement";
import { GuichetUniqueService } from "../../services";

const GlobalContext = createContext<GlobalContextData>({} as GlobalContextData);

const GlobalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const values = useGlobalProvider();
  return (
    <GlobalContext.Provider value={values}>{children}</GlobalContext.Provider>
  );
};

function useGlobalProvider() {
  const [etat, setEtat] = useState<number>(1);
  // const {uuid} = useParams();
  // const [uuid, setUuid] = useState("");
  const [loading, setLoading] = useState<boolean>(true);
  const guichetUniqueService = useMemo(() => new GuichetUniqueService(), []);
  const [errorUuid, setErrorUuid] = useState(false);
  const [checkUuidTransaction, setCheckUuidTransaction] =
    useState<ResponseCheckUuidtransaction>();
  const [loadingMobileMoney, setLoadingMobileMoney] = useState<boolean>(false);
  const [reponsePayement, setReponseEtat] = useState<any>();
  const [walletpayurl, setWalletPayUrl] = useState<string>("");
  const [loadingWalletPaiement, setLoadingWalletPaiement] =
    useState<boolean>(false);
  const [stateWalletPaiement, setStateWalletPaiement] = useState<string>("");
  const [stateMalitelPaiement, setStateMalitelPaiement] = useState<string>("");
  const [stateWaveSnChanged, setStateWaveSnChanged] = useState<boolean>(false);
  const [stateMalitelChanged, setStateMalitelChanged] =
    useState<boolean>(false);
  let check = true;
  const uuid = useParams().uuid!.split(".")[1] ?? "";
  const country = useParams().uuid!.split(".")[0] ?? "";
  // useEffect(() => {
  //     if (uuid && check) {
  //         check = false
  //         uuid =
  //         handleUuid();
  //     }
  // }, [uuid])
  useEffect(() => {
    if (check && uuid) {
      check = false;
      handleUuid();
    }
  }, []);

  const handleWalletPayement = (payment: WaveForm) => {
    setLoadingWalletPaiement(true);
    guichetUniqueService
      .initWavePayment(payment)
      .then((data) => {
        // setEtat(4)
        // console.log(data?.data?.payload)
        if (walletpayurl !== data?.data?.payload.wave_launch_url) {
          setWalletPayUrl(data?.data?.payload.wave_launch_url);
          console.log(walletpayurl);
          setLoadingWalletPaiement(false);
          // console.log(data?.data?.payload.wave_launch_url);
          setStateWalletPaiement("PENDING");
        }
        // setReponseEtat(data?.data?.payload)
      })
      .catch((error) => {
        // console.log(error)
        // alert('No Connection')
        setLoadingWalletPaiement(false);
      });
  };
  const handleProceedPayement = (mobile: MobileMoneyForm) => {
    setLoadingMobileMoney(true);
    guichetUniqueService
      .proccedPayment(mobile)
      .then((data) => {
        if (data?.data?.payload?.codeService == "COPOrangeMoney") {
          setLoadingMobileMoney(false);
          setEtat(4);
          setReponseEtat(data?.data?.payload);
        } else if (data?.data?.payload?.codeService == "COPMoovMoney") {
          console.log(data?.data?.payload);
          console.log(data?.data?.payload.Status);
          if (uuid) {
            if (
              stateMalitelPaiement !== "FAILED" &&
              stateMalitelPaiement !== "SUCCESS"
            ) {
              const intervalId = window.setInterval(function () {
                if (
                  stateMalitelPaiement === "PENDING" ||
                  !stateMalitelChanged
                ) {
                  guichetUniqueService
                    .checkMalitelPayment(uuid)
                    .then((data) => {
                      setStateMalitelPaiement(data?.data?.payload.Status);
                      if (
                        data?.data?.payload.Status === "FAILED" ||
                        data?.data?.payload.Status === "SUCCESS"
                      ) {
                        clearInterval(intervalId);
                        setStateMalitelChanged(true);
                        setLoadingMobileMoney(false);
                        setEtat(4);
                        // console.log(data?.data?.payload)
                        setReponseEtat(data?.data?.payload);
                      }
                    })
                    .catch((error) => {
                      setErrorUuid(true);
                    });
                } else {
                  clearInterval(intervalId);
                }
              }, 5000);
            }
          }
        } else if (data?.data?.payload?.codeService == "COPOrangeMoneyMali") {
          console.clear();
          // if (data?.data?.payload?.paiementUrl !== "")
          //     window.location.href = data?.data?.payload?.paiementUrl;
          // console.log(data?.data?.payload);
          // console.log(data?.data?.payload?.paiementUrl);
          const externalURL =
            data?.data?.payload?.paiementUrl ?? "https://alimha.com";
          window.location.href = externalURL;
        }
      })
      .catch((error) => {
        alert("No Connection");
        setLoadingMobileMoney(false);
      });
  };
  const checkWalletPaymentState = () => {
    if (uuid) {
      if (
        stateWalletPaiement !== "FAILED" &&
        stateWalletPaiement !== "SUCCESS"
      ) {
        // const intervalId = window.setInterval(function () {
        if (stateWalletPaiement === "PENDING" || !stateWaveSnChanged) {
          guichetUniqueService
            .checkWalletPayment(uuid)
            .then((data) => {
              setStateWalletPaiement(data?.data?.payload.Status);
              // console.log("data " + data?.data?.payload.Status);
              // console.log("state wallet " + stateWalletPaiement);
              if (
                data?.data?.payload.Status === "FAILED" ||
                data?.data?.payload.Status === "SUCCESS"
              ) {
                // clearInterval(intervalId);
                setStateWaveSnChanged(true);
                setEtat(4);
                // console.log(data?.data?.payload)
                setReponseEtat(data?.data?.payload);
                return stateWalletPaiement;
              }
            })
            .catch((error) => {
              setErrorUuid(true);
              return stateWalletPaiement;
            });
        }
        // else {
        //     clearInterval(intervalId);
        // }
        // }, 5000);
      }
    }
  };
  const handleUuid = () => {
    if (uuid) {
      guichetUniqueService
        .checkUuid(uuid, country)
        .then((data) => {
          // console.log(data);
          setLoading(false);
          setCheckUuidTransaction(
            ResponseCheckUuidtransaction.deserialize(data)
          );
        })
        .catch((error) => {
          setLoading(false);
          setErrorUuid(true);
        });
    }
  };

  return {
    etat,
    loading,
    errorUuid,
    checkUuidTransaction,
    setEtat,
    setErrorUuid,
    setLoading,
    loadingMobileMoney,
    loadingWalletPaiement,
    uuid,
    handleProceedPayement,
    handleWalletPayement,
    checkWalletPaymentState,
    reponsePayement,
    walletpayurl,
    stateWalletPaiement,
  };
}

const useGlobalContext = () => {
  if (useContext(GlobalContext) === undefined) {
    throw new Error("GlobalContext must be used within a Provider");
  }
  return useContext<GlobalContextData>(GlobalContext);
};
export { GlobalProvider, useGlobalContext };
