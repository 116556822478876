import {useForm, Resolver} from "react-hook-form";
import {ResponseCheckUuidtransaction} from "../../../model";
import {useEffect, useState} from "react";
import {CardBankProps} from "./CardBankProps";
import {BsFileLockFill, BsFillCalendarCheckFill, BsFillCreditCard2BackFill, BsPersonLinesFill} from 'react-icons/bs'
import {MASTERCARD, VISA} from "../../../assets";
import {PatternFormat} from 'react-number-format';
import {useGlobalContext} from "../../../context/Global";

const CardBank: React.FC<CardBankProps> = ({transaction}) => {

    const [loadingPayementCard, setLoadingPayementCard] = useState(false);
    const {setEtat} = useGlobalContext();

    type FormValues = {
        fullname: string;
        numerocarte: string;
        dateexp: string;
        cvv: string
    };

    const resolver: Resolver<FormValues> = async (values) => {
        return {
            values: values.fullname && values.numerocarte && values.dateexp && values.cvv ? values : {},
            errors: checkError(values)

        };
    };
    const checkError = (data: FormValues) => {
        let response = {};
        if (!data?.fullname) {
            response = {
                ...response, fullname: {
                    type: 'required',
                    message: 'Veuiller remplir ce champ',
                }
            }
        }

        if (!data?.dateexp) {
            response = {
                ...response, dateexp: {
                    type: 'required',
                    message: 'Veuiller remplir ce champ',
                }
            }
        }
        if (!data?.cvv) {
            response = {
                ...response, cvv: {
                    type: 'required',
                    message: 'Veuiller remplir ce champ',
                }
            }
        }
        if (!data?.numerocarte) {
            response = {
                ...response, numerocarte: {
                    type: 'required',
                    message: 'Veuiller remplir ce champ',
                }
            }
        }

        return response;
    }

    const {register, handleSubmit, formState: {errors}} = useForm<FormValues>({resolver});

    function simulatePaiement() {
        setLoadingPayementCard(true);
        setTimeout(function () {
            setLoadingPayementCard(false);
            setEtat(4)
        }, 4000);
    }

    return (
        <form>
            <div className="row">
                <div className="col-sm-12">
                    <div className="input-copay-group mb-3">
                        <div className="prepend-copay">
                            <span id="basic-addon1"><BsPersonLinesFill style={{height: 40}}/></span>
                        </div>
                        <input  {...register("fullname")} type="text" autoComplete='none'
                                className="form-control input-copay"
                                placeholder="Prénom et Nom" aria-label="Username"
                                aria-describedby="basic-addon1"/>
                        {errors?.fullname && <p className="errorMessage">{errors.fullname.message}</p>}
                    </div>

                </div>
                <div className="col-sm-12">
                    <div className="input-copay-group mb-3">
                        <div className="prepend-copay">
                            <span id="basic-addon1"><BsFillCreditCard2BackFill style={{height: 40}}/></span>
                        </div>
                        <PatternFormat {...register("numerocarte")}
                                       valueIsNumericString format="#### #### #### ####"
                                       className="form-control input-copay"
                                       placeholder="Numéro de la Carte" aria-label="Username"
                                       aria-describedby="basic-addon1"
                        />
                        {/*<input  {...register("numerocarte")} type="number"*/}
                        {/*        className="form-control input-copay"*/}
                        {/*        placeholder="Numéro de la Carte" aria-label="Username"*/}
                        {/*        aria-describedby="basic-addon1"/>*/}
                        {errors?.numerocarte && <p className="errorMessage">{errors.numerocarte.message}</p>}
                    </div>

                </div>
                <div className="col-sm-12 col-md-7">
                    <div className="input-copay-group mb-3">
                        <div className="prepend-copay">
                            <span id="basic-addon1"><BsFillCalendarCheckFill style={{height: 40}}/></span>
                        </div>
                        <input  {...register("dateexp")} type="month"
                                className="form-control input-copay text-end"
                                placeholder="(MM/YY)" aria-label="Username"
                                aria-describedby="basic-addon1"/>
                        {errors?.dateexp && <p className="errorMessage">{errors.dateexp.message}</p>}
                    </div>

                </div>


                <div className="col-sm-12 col-md-5">
                    <div className="input-copay-group mb-3">
                        <div className="prepend-copay">
                            <span id="basic-addon1"><BsFileLockFill style={{height: 40}}/></span>
                        </div>
                        <PatternFormat  {...register("cvv")}
                                        className="form-control input-copay text-end"
                                        placeholder="CVV" aria-label="cvv" valueIsNumericString format="###"
                                        aria-describedby="basic-addon1"/>
                        {errors?.cvv && <p className="errorMessage">{errors.cvv.message}</p>}
                    </div>

                </div>
                <div className="col-sm-12">
                    <button disabled={loadingPayementCard} type="button" className="btn-copay"
                            onClick={simulatePaiement}
                            style={{height: "54px"}}>
                        <span>{!loadingPayementCard ? "PAYER " + transaction?.transaction.montantPaye + " XOF" : 'Paiement en cours ...'}</span>
                    </button>
                    <div className='containerImage mt-3 '>
                        <div className='litteTitle'>
                            Paiement Sécurisé
                        </div>
                        <div className="image-partenaire">
                            <img src={VISA}/>
                            <img src={MASTERCARD}/>
                        </div>
                    </div>
                </div>

            </div>

        </form>
    )


}

export default CardBank