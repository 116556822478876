import { useGlobalContext } from "../../context/Global";

const usePage = () => {
  const {
    etat,
    loading,
    errorUuid,
    checkUuidTransaction,
    setEtat,
    setErrorUuid,
    setLoading,
    loadingMobileMoney,
  } = useGlobalContext();

  return {
    etat,
    loading,
    errorUuid,
    checkUuidTransaction,
    setEtat,
    setErrorUuid,
    setLoading,
    loadingMobileMoney,
  };
};

export { usePage };
