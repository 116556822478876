import {useGlobalContext} from "../../../context/Global";

const useWallet = () => {
    const {
        loadingWalletPaiement,
        handleWalletPayement,
        checkWalletPaymentState,
        uuid,
        walletpayurl,
    } = useGlobalContext();
    const initWalletPayment = () => {
        if (uuid) {
            handleWalletPayement({
                // uuid: transaction.transaction.uuid,
                uuid: uuid,
                service: "COPWave",
            });
        }
    }
    return {
        loadingWalletPaiement,
        initWalletPayment,
        walletpayurl,
        checkWalletPaymentState,
    }

}

export {useWallet}