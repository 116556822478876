import React, {useEffect, useState} from 'react';
import {QRCODE} from '../../../assets';
import {WalletProps} from "./WalletProps";
import {useWallet} from "./useWallet";
import {Loading} from "../component";
import {WaveForm} from "../../../model/payement";
import QRCode from "react-qr-code";

const Wallet: React.FC<WalletProps> = ({transaction}) => {
    const [checkStatus, setCheckStatus] = useState(false);
    const {
        loadingWalletPaiement,
        initWalletPayment,
        checkWalletPaymentState,
        walletpayurl,
    } = useWallet();
    const [alreadyLoad, setAlreadyLoad] = useState(false);
    if (transaction && !alreadyLoad) {
        initWalletPayment();
        setAlreadyLoad(true);
    }

    const chechWavePaymentByClick = async () => {
        setCheckStatus(true);
        const responseWave = await checkWalletPaymentState();
        console.log(responseWave);
        setTimeout(()=>{
            setCheckStatus(false);
        }, 1000);
    };

    useEffect(() => {
        if (walletpayurl !== "") {
            // checkWalletPaymentState();
        }
    }, [walletpayurl]);

    // console.log(transaction);
    return (loadingWalletPaiement ? <div className="d-flex justify-content-center my-5 py-5">
                <div className="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div> :
            <div>
                <div className="row wallet-card justify-content-center">
                    <div className='col-sm-12 title-wallet'>Scannez le QR CODE SVP <span>&#128071;</span></div>

                    <div className='col-sm-10 wallet-image my-4'>
                        <QRCode
                            // size={256}
                            fgColor="#031926"
                            // style={{height: "auto", maxWidth: "100%", "alignSelf": "center"}}
                            value={walletpayurl}
                        />
                        {/*<img src={QRCODE}/>*/}
                    </div>
                    <div className='rowbtncopay d-block d-md-none'>
                        <div className='col-sm-12 title-wallet'>Ou cliquez ici <span>&#128071;</span></div>

                        <div className="col-sm-12 mt-4">
                            <a href={walletpayurl}
                               className="btn-copay text-center text-decoration-none text-white">PAYER {transaction?.transaction.montantPaye} XOF</a>
                        </div>
                    </div>
                    <div className='rowbtncopay'>
                        <div className="col-sm-12 mt-4">
                            <a onClick={chechWavePaymentByClick}
                               className="btn-copay text-center text-decoration-none text-white cursor-pointer">{checkStatus ? "..." : "VERIFIER LE PAIEMENT"}</a>
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default Wallet;