import { useGlobalContext } from "../../../context/Global"

const usePayemetValide = ()=>{

    const {reponsePayement, checkUuidTransaction} = useGlobalContext();

    return{
        reponsePayement, checkUuidTransaction
    }
}
export {usePayemetValide}