import React from 'react';
import { BrowserRouter, Navigate, Route,Routes } from 'react-router-dom'
import { GlobalProvider } from '../context/Global';
import { GuichetPage } from '../pages/Guichet';
import { NotFound } from '../pages/Guichet/NotFound';



const AppRoutes = () => {
    document.title = "ALIMHA | Guichet unique";
    return (
        <BrowserRouter>
            <Routes >
                <Route path={'/:uuid'}
                        element={
                            <GlobalProvider>
                                <GuichetPage/>
                            </GlobalProvider>
                }/>
                 <Route path={'/page/notfound'}
                        element={
                            <NotFound/>
                }/>
                <Route 
                path="*" element={<Navigate to="/page/notfound" />} />
            </Routes>
        </BrowserRouter>
    );
}

export {AppRoutes}
